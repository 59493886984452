<template>
    <div class="tabbar-box">
        <TabbarH v-if="$store.getters.currentPageVersion === 'Horizontal'" 
        @selectTab="selectTab"
        :TabbarList="TabbarList"></TabbarH>
        <TabbarV v-if="$store.getters.currentPageVersion === 'Vertical'"
        @selectTab="selectTab" 
        :TabbarList="TabbarList"></TabbarV>
    </div>
</template>

<script>
    import TabbarH from '@/components/Layout/Tabbar/TabbarH.vue'
    import TabbarV from '@/components/Layout/Tabbar/TabbarV.vue'
    
    
    import home from '@/assets/tabbar/V/tab_home.svg'
    import homeS from '@/assets/tabbar/V/tab_home_s.svg'
    import customer from '@/assets/tabbar/V/tab_customer.svg'
    import customerS from '@/assets/tabbar/V/tab_customer_s.svg'
    import gift from '@/assets/tabbar/V/tab_gift.svg'
    import giftS from '@/assets/tabbar/V/tab_gift_s.svg'
    import mine from '@/assets/tabbar/V/tab_mine.svg'
    import mineS from '@/assets/tabbar/V/tab_mine_s.svg'
    import xBox from '@/assets/tabbar/V/tab_xbox.svg'
    import xBoxS from '@/assets/tabbar/V/tab_xbox_s.svg'

    export default {
        components: {
            TabbarH,
            TabbarV
        },
        data() {
            return {
                TabbarList: [
                    {
                        title: "我的",
                        icon: mine,
                        iconS: mineS,
                        root:'/mine'
                    },
                    {
                        title: "福利",
                        icon: gift,
                        iconS: giftS,
                        root:'/welfare'
                    },
                    {
                        title: "攻略",
                        icon: xBox,
                        iconS: xBoxS,
                        root:'/strategy'
                    },
                    {
                        title: "客服",
                        icon: customer,
                        iconS: customerS,
                        root:'/customer'
                    }
                ]
            }
        },
        created() {
        //   this.initTabbarMod()  
        },
        methods: {
            initTabbarMod() {
              if(this.$store.state.currentVersion === "BuyingTraffic" ) {
                  this.TabbarList = [
                      {
                          title: "首页",
                          icon: home,
                          iconS: homeS,
                          root:'/index'
                      },
                      {
                          title: "我的",
                          icon: mine,
                          iconS: mineS,
                          root:'/mine'
                      },
                      {
                          title: "福利",
                          icon: gift,
                          iconS: giftS,
                          root:'/welfare'
                      },
                      {
                          title: "攻略",
                          icon: xBox,
                          iconS: xBoxS,
                          root:'/strategy'
                      },
                      {
                          title: "客服",
                          icon: customer,
                          iconS: customerS,
                          root:'/customer'
                      }
                  ]
              }  
            },
            selectTab(e) {
                console.log(e)
                let pushPath = null
                switch (e.root) {
                    case '/index':
                        pushPath = 'Index';
                        break;
                    case '/mine':
                        pushPath = 'Mine';
                        break;
                    case '/welfare':
                        pushPath = 'WelfareIndex';
                        break;
                    case '/strategy':
                        pushPath = 'StrategyIndex';
                        break;
                    case '/customer':
                        pushPath = 'CustomerIndex';
                        break;
                    default:
                        break;
                }
                this.$router.push({
                    name: pushPath,
                    params: {}
                }).catch(()=> {
                   
                })
            }
        },
        mounted() {
            // console.log(this.$route.matched[2].path === '/index/inviteIndex')
        }
    }
</script>

<style scoped>
    .tabbar-box {
        width: 100%;
    }
</style>
