<template>
    <div class="index-box">
        <RoleInfo v-if="$route.matched[1].path === '/index'"></RoleInfo>
        <UserInfo v-else></UserInfo>
        <Tabbar></Tabbar>
        <div class="bottom-box">
            <Exchange v-if="$route.matched[1].path === '/index'"></Exchange>
            <SignRedPacket style="padding-top: 14px;" @openSignRedPacketDialog="openSignRedPacketDialog"
                v-if="$store.state.switchStatusOfRedPacket.RedPacketOfSign === 1 && $route.matched[1].path === '/index'"></SignRedPacket>
            <div class="blank-index-back-box" v-if="$route.matched[1].path !== '/index'">
                <img src="@/assets/blank/blank_index_back.svg">
            </div>
            
        </div>
    </div>
</template>

<script>
    import RoleInfo from '@/components/Index/IndexRedPacket/Horizontal/RoleInfo.vue'
    import UserInfo from '@/components/Mine/Index/Horizontal/UserInfo.vue'
    import Tabbar from '@/components/Layout/Tabbar/Index.vue'
    import SignRedPacket from '@/components/Index/IndexRedPacket/SignRedPacket/Index.vue'
    import Exchange from '@/components/Index/IndexRedPacket/Exchange/Index.vue'
    export default {
        components: {
            RoleInfo,
            UserInfo,
            Tabbar,
            SignRedPacket,
            Exchange
        },
        data() {
            return {
            }
        },
        methods: {
            //关闭红包领取成功的弹窗
            closeRedGetDialog() {
                this.$emit('closeRedGetDialog')
            },
            //打开签到红包弹窗
            openSignRedPacketDialog(money) {
                this.$emit('openSignRedPacketDialog', money)
            }
        }
    }
</script>

<style scoped>
    .index-box {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color: #F4F4F4;
    }

    .bottom-box {
        width: 100%;
        flex: 1;
        overflow: hidden;
        padding: 8.75px 8.75px 11.875px 8.75px;
        background-color: #ffffff;
    }

    .red-packet-content {
        height: 100%;
        overflow: scroll;
    }
    .blank-index-back-box {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .blank-index-back {
        width: 108px;
    }
</style>