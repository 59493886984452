<template>
    <div class="tabbar-list">
        <div class="tabbar-item-box" v-for="(item,index) in TabbarList" :key="index" @click="selectTab(item)">
            <img style="width: 34px;" :src="$route.matched[1]&&item.root === $route.matched[1].path?item.iconS:item.icon">
            <span :class="$route.matched[1]&&item.root === $route.matched[1].path?'text-s':'text'">{{item.title}}</span>
        </div>
    </div>
</template>
<script>
    export default {
       props:{
           TabbarList:Array
       },
       data() {
           return {
              
           }
       },
       methods: {
           selectTab(e) {
               this.$emit('selectTab', e)
           }
       }
    }
</script>

<style scoped>
    .tabbar-list {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        overflow-y: auto;
        padding-bottom: 20px;
    }
    .tabbar-item-box {
        width: 100%;
        min-height: 85px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: column;
    }
    .text {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 17px;
        color: #999999;
    }
    .text-s {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 17px;
        color: #16C5CE;
    }
</style>