<template>
    <div class="tabbar-list-box">
        <div class="tabbar-list">
            <div class="tabbar-item-box" v-for="(item,index) in TabbarList" :key="index" @click="selectTab(item)">
                <img class="tabbar-icon" :src="$route.matched[1]&&item.root === $route.matched[1].path?item.iconS:item.icon">
                <span :class="$route.matched[1]&&item.root === $route.matched[1].path?'text-s':'text'">{{item.title}}</span>
            </div>
        </div>
    </div>
    
</template>
<script>
    
    export default {
        props:{
            TabbarList:Array
        },
        data() {
            return {
               
            }
        },
        methods: {
            selectTab(e) {
                this.$emit('selectTab', e)
            }
        }
    }
</script>

<style scoped>
    .tabbar-list-box {
        padding-top: 1px;
    }
    .tabbar-list {
        width: 100%;
        height: 100%;
        padding: 2px 12.5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #F7FDFE;
        box-shadow: -2px 3px 5px 1px rgba(54,43,8,0.1);
        position: relative;
    }

    .tabbar-item-box {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .tabbar-icon {
        width: 21px;
    }

    .text {
        font-size: 8px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 10px;
        color: #999999;
    }

    .text-s {
        font-size: 8px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 10px;
        color: #16C5CE;
    }
</style>
