<template>
    <div>
        <router-view ref="horizontalChildRoute" :key="$route.name"/>
    </div>
</template>

<script>
    export default {
        components:{
        },
        props:{
        },
        data() {
            return {
                
            }
        },
        methods:{
            closeRedGetDialog() {
                
                if(this.$refs.horizontalChildRoute.closeRedGetDialog) {
                    this.$refs.horizontalChildRoute.closeRedGetDialog()
                }
            },
            openSignRedPacketDialog(money) {
                if(this.$refs.horizontalChildRoute.openSignRedPacketDialog) {
                    this.$refs.horizontalChildRoute.openSignRedPacketDialog(money)
                }
            },
        }
    }
</script>

<style scoped>
</style>