<template>
    <!-- 横版 -->
    <div class="h-page-box">
        <div class="h-page" v-if="$store.getters.currentPageVersion === 'Horizontal'">
            <IndexH class="index-box" @closeRedGetDialog="closeRedGetDialog" @openSignRedPacketDialog="openSignRedPacketDialog"></IndexH>
            <Content ref="horizontalChild" class="content-box"></Content>
        </div>
       <div class="h-page" v-else-if="$store.getters.currentPageVersion === 'Vertical'">
           <ContentV class="content-box"></ContentV>
           <TabbarV class="tabbar-box" :class="$route.matched[2]&&($route.matched[2].path === '/index/inviteIndex'||$route.matched[2].path === '/index/inviteImage') ?'hidden-tabbar':''"></TabbarV>
       </div>
       <div class="h-page" v-else>
           初始化失败！请重新进入
       </div>
    </div>
</template>

<script>
    import Content from '@/components/Layout/Content/ContentH/ContentH.vue'
    import IndexH from '@/components/Layout/Content/ContentH/IndexH.vue'
    import ContentV from '@/components/Layout/Content/ContentV.vue'
    import TabbarV from '@/components/Layout/Tabbar/Index.vue'
    export default {
        components:{
            Content,
            IndexH,
            ContentV,
            TabbarV
        },
        methods:{
            closeRedGetDialog() {
                this.$refs.horizontalChild.closeRedGetDialog()
            },
            //打开签到红包弹窗
            openSignRedPacketDialog(money) {
                this.$refs.horizontalChild.openSignRedPacketDialog(money)
            },
        }
    }
</script>

<style >
    /deep/.van-toast {
        padding: 0 8px !important;
        flex-direction: row !important;
        width: auto !important;
        min-height: 8.75px !important;
    }
    
    /deep/.van-toast__text {
        font-size: 8.75px;
        margin-top: 0 !important;
    }
    /deep/.van-loading__spinner {
        width: 16px !important;
    }
</style>

<style scoped>
    
    /* px尺寸按设计稿的0.7倍换算*/
    .h-page-box {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
    }
    .h-page {
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        display: flex;
        align-items: center;
    }
    .content-box {
        height: 100%;
        flex: 1;
        overflow: hidden;
    }
    .index-box {
        height: 100%;
        width: 47%;
        padding-right: 0.5vw;
    }
    .tabbar-box {
        width: 58px;
        height: 100%;
        background: #F5FAFA;
    }
    .hidden-tabbar {
        width: 0;
        display: none;
    }
</style>