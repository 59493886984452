<template>
    <!-- 竖版 -->
    <div class="v-page-box">
        <div class="v-page">
            <Tabbar class="tabbar-box" :class="$route.matched[2]&&($route.matched[2].path === '/index/inviteIndex'||$route.matched[2].path === '/index/inviteImage') ?'hidden-tabbar':''"></Tabbar>
            <Content class="content-box"></Content>
        </div>
    </div>
</template>

<script>
    import Content from '@/components/Layout/Content/ContentV.vue'
    import Tabbar from '@/components/Layout/Tabbar/Index.vue'
    
    export default {
        components:{
            Content,
            Tabbar
        },
        created() {
            // console.log(this.$route.matched[2].path)
        }
    }
</script>

<style scoped>
    .v-page-box {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
    }
    .v-page {
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
    .content-box {
        height: 100%;
        flex: 1;
        overflow: hidden;
    }
    .tabbar-box {
        width: 58px;
        height: 100%;
        background: #F5FAFA;
    }
    .hidden-tabbar {
        width: 0;
        display: none;
    }
</style>