import { render, staticRenderFns } from "./layoutVertical.vue?vue&type=template&id=685aa880&scoped=true&"
import script from "./layoutVertical.vue?vue&type=script&lang=js&"
export * from "./layoutVertical.vue?vue&type=script&lang=js&"
import style0 from "./layoutVertical.vue?vue&type=style&index=0&id=685aa880&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "685aa880",
  null
  
)

export default component.exports