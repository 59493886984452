<template>
    <div class="home">
        <HorizontalPage v-if="$store.state.screenOrientation === 'Horizontal' && initStatus"></HorizontalPage>
        <VerticalPage v-if="$store.state.screenOrientation === 'Vertical' && initStatus"></VerticalPage>
        <div v-if="$store.state.currentVersion === 'error'" style="
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #d4d4d4;
      ">
            页面错误！请重新打开或联系客服
        </div>
        <remote-js :srcArr="erudaArr" v-if="isOpenDebug"></remote-js>
    </div>
</template>

<script>
    // @ is an alias to /src
    import HorizontalPage from "@/views/layout/layoutHorizontal.vue";
    import VerticalPage from "@/views/layout/layoutVertical.vue";

    export default {
        name: "Home",
        components: {
            HorizontalPage,
            VerticalPage,
        },
        data() {
            return {
                isGetAllParams: false, //是否获取完整所有参数
                initQiyuTimer: null, //七鱼客服组件轮询定时器
                initStatus: false,
                isOpenDebug: false,
                erudaArr: ["https://cdn.bootcss.com/eruda/1.5.8/eruda.min.js"],
            };
        },
        created() {
            if (this.isOpenDebug) {
                this.erudaCallbackFn();
                return;
            }
            console.log("悬浮球初始化");
            if (
                this.$store.state.extInfo === null ||
                this.$store.state.token === null ||
                this.$store.state.userId === null
            ) {
                this.getRequestPublicParame();
            } else {
                this.isGetAllParams = true;
                this.initSdkFlot();
            }
        },
        mounted() {
            if (
                typeof window.iosGetUserTokenCallback !== "function" &&
                this.$common.getNowSystem() === "ios"
            ) {
                window.iosGetUserTokenCallback = this.iosGetUserTokenCallback;
                window.iosGetExtInfoCallback = this.iosGetExtInfoCallback;
                window.iosGetMiniIdCallback = this.iosGetMiniIdCallback;
                window.iosGetPlatformRoleIdCallback = this.iosGetPlatformRoleIdCallback;
                window.iosGetUserEnterGameDateCallback =
                    this.iosGetUserEnterGameDateCallback;
                window.iosGetScreenOrientationCallback =
                    this.iosGetScreenOrientationCallback;
            }
        },
        methods: {
            //开启调试模式
            erudaCallbackFn() {
                let dddtimer = setInterval(() => {
                    console.log(eruda);
                    if (eruda && eruda._isInit === false) {
                        eruda.init();
                        clearInterval(dddtimer);
                        if (
                            this.$store.state.extInfo === null ||
                            this.$store.state.token === null ||
                            this.$store.state.userId === null
                        ) {
                            this.getRequestPublicParame();
                        } else {
                            this.isGetAllParams = true;
                            this.initSdkFlot();
                        }
                    }
                }, 100);
            },
            //悬浮球初始化
            initSdkFlot() {
                console.log("悬浮球初始化03");
                this.$api.user
                    .init({
                        user_id: this.$store.state.userId,
                        token: this.$store.state.token,
                    })
                    .then((res) => {
                        if (res.data.code === 200) {
                            let data = res.data.data;
                            this.$store.commit("getBindPhone", data.phone || null);
                            this.$store.commit("getPhoneBindState", data.set_phone);
                            this.$store.commit("getUserAccount", data.account);
                            this.$store.commit("getAccountPasswordSetState", data.set_password);
                            this.$store.commit("getPayPasswordSetState", data.set_pay_password);
                            this.$store.commit("getServiceType", data.service_type);
                            this.$store.commit("getServiceConfig", data.service_cont);
                            this.$store.commit("getTenantCode", data.tenant_code);
                            this.$store.commit("getRootDomain", data.root_domain);
                            //获取平台币余额
                            this.$store.dispatch("getPlatFormCoinBalance", this);
                            this.initStatus = true;
                            //初始化客服
                            switch (data.service_type) {
                                case "qykf":
                                    if (typeof ysf !== "function") {
                                        this.initQiYuServe();
                                    }

                                    break;
                                case "dezhu":
                                    this.initDezhuServe();
                                    break;
                                default:
                                    break;
                            }
                            console.log(this.$route);
                            //悬浮球初始化成功后判断要重定向到哪个页面
                            this.$common.redirectWhenReload(this);
                        }
                    });
            },

            //获取请求公共参数
            async getRequestPublicParame() {
                let extInfo = {},
                    token = null,
                    userId = null,
                    roleId = null;
                switch (this.$common.getNowSystem()) {
                    case "ios":
                        console.log("获取参数");
                        this.$common.postMessageToIos("getExtInfo", null);
                        this.$common.postMessageToIos("getUserToken", null);
                        this.$common.postMessageToIos("getUserId", null);
                        this.$common.postMessageToIos("getPlatformRoleId", null);
                        this.$common.postMessageToIos("getUserEnterGameDate", null);
                        this.$common.postMessageToIos("getScreenOrientation", null);
                        // this.$common.postMessageToIos('getVersionOfFloatPage', null)
                        return;
                    case "Android":
                        extInfo = android.getExtInfo();
                        token = android.getUserToken();
                        userId = android.getUserId();
                        let userEnterGameDate = {},
                            screenOrientation = null,
                            versionOfFloatPage = null
                        try {
                            roleId = android.getPlatformRoleId();
                            userEnterGameDate = JSON.parse(android.getUserEnterGameDate());
                            screenOrientation = android.getScreenOrientation();
                            // versionOfFloatPage = android.getVersionOfFloatPage()
                        } catch (e) {
                            roleId = undefined
                            userEnterGameDate = "当前版本不支持"
                            this.$common.screenOrientation()
                            this.$store.commit("getCurrentVersion", "DedicatedServers");
                        }

                        console.log(
                            "JSON.parse(android.getUserEnterGameDate()): " +
                            JSON.stringify(userEnterGameDate)
                        );
                        this.saveRequestParame(
                            extInfo,
                            token,
                            userId,
                            roleId,
                            userEnterGameDate,
                            screenOrientation,
                            versionOfFloatPage
                        );
                        break;
                    default:
                        window.addEventListener("message", this.listenerFunction);
                        this.$common.postMessageToWindowParent("getExtInfo", null);
                        this.$common.postMessageToWindowParent("getUserToken", null);
                        this.$common.postMessageToWindowParent("getUserId", null);
                        this.$common.postMessageToWindowParent("getPlatformRoleId", null);
                        this.$common.postMessageToWindowParent(
                            "getUserSignRedPacketState",
                            null
                        );
                        this.$common.postMessageToWindowParent("getScreenOrientation", null);
                        // this.$common.postMessageToWindowParent('getVersionOfFloatPage', null)
                        break;
                }
            },
            listenerFunction(e) {
                let that = this;
                switch (e.data.gsFloatEventType) {
                    case "getUserTokenCallback":
                        that.saveRequestParame(null, e.data.data, null, null);
                        break;
                    case "getExtInfoCallback":
                        that.saveRequestParame(JSON.stringify(e.data.data), null, null, null);
                        break;
                    case "getUserIdCallback":
                        that.saveRequestParame(null, null, e.data.data, null);
                        break;
                    case "getPlatformRoleIdCallback":
                        that.saveRequestParame(null, null, null, e.data.data);
                        break;
                    case "getUserEnterGameDateCallback":
                        that.saveRequestParame(
                            null,
                            null,
                            null,
                            null,
                            JSON.parse(e.data.data)
                        );
                        break;
                    case "getScreenOrientationCallback":
                        let screenOrientation = null;
                        switch (e.data.data) {
                            case "horizontal":
                                screenOrientation = "Horizontal";
                                break;
                            case "vertical":
                                screenOrientation = "Vertical";
                                break;
                            default:
                                screenOrientation = "Vertical";
                                break;
                        }
                        that.saveRequestParame(
                            null,
                            null,
                            null,
                            null,
                            null,
                            screenOrientation
                        );
                        break;
                    case "getVersionOfFloatPageCallback":
                        console.log("getVersionOfFloatPageCallback");
                        console.log(e.data.data);
                        that.saveRequestParame(
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            e.data.data
                        );
                        break;
                }
            },
            saveRequestParame(
                extInfo,
                token,
                userId,
                roleId,
                enterDate,
                screenOrientation,
                versionOfFloatPage
            ) {
                if (this.isGetAllParams) {
                    //已经获取到所有参数的情况下防止重复初始化
                    return;
                }
                if (extInfo) {
                    this.$store.commit("getExtInfo", extInfo);
                    // localStorage.setItem('extInfo', extInfo)
                }
                if (token) {
                    this.$store.commit("getToken", token);
                    // localStorage.setItem('token', token)
                }
                if (userId) {
                    console.log("userId:" + userId);
                    this.$store.commit("getUserId", userId);
                    // localStorage.setItem('userId', userId)
                }

                if (!roleId && roleId !== null) {
                    console.log("没有roleID");
                    this.$store.commit("getPlatformRoleId", "暂无角色信息");
                } else if (roleId) {
                    this.$store.commit("getPlatformRoleId", roleId);
                }
                if (enterDate) {
                    console.log("getUserEnterGameDate");
                    console.log(enterDate);
                    this.$store.commit("getUserEnterGameDate", enterDate);
                }
                if (screenOrientation) {
                    this.$store.commit("getScreenOrientation", screenOrientation);
                    // if (screenOrientation === 'Horizontal') {
                    //     console.log(document.getElementsByClassName("van-toast"))
                    // 	document.getElementsByClassName("van-toast")[0].style.fontSize = "10px"
                    // } else {

                    // }
                }
                if (versionOfFloatPage) {
                    // switch(versionOfFloatPage) {
                    //     case "1":
                    //     case 1:
                    //         this.$store.commit('getCurrentVersion', 'DedicatedServers')
                    //         break;
                    //     case "2":
                    //     case 2:
                    //         this.$store.commit('getCurrentVersion', 'BuyingTraffic')
                    //         break;
                    //     case "3":
                    //     case 3:
                    //         this.$store.commit('getCurrentVersion', 'Discount')
                    //         break;
                    //     default:
                    //         this.$store.commit('getCurrentVersion', 'error')
                    //         break;
                    // }
                } else {
                    //版本获取中的状态
                    // this.$store.commit('getCurrentVersion', 'geting')
                }
                if (
                    this.$store.state.extInfo !== null &&
                    this.$store.state.token !== null &&
                    this.$store.state.userId !== null &&
                    this.$store.state.roleId !== null &&
                    this.$store.state.userEnterDate !== null &&
                    this.$store.state.screenOrientation !== null
                ) {
                    // console.log("this.$route.query.redirect")
                    // //执行获取完请求参数后的流程
                    console.log(this.$store.state.currentVersion)
                    if (this.$store.state.currentVersion === 'geting' ||
                        this.$store.state.currentVersion === 'error') {
                        this.$store.commit('getCurrentVersion', 'error')
                        return
                    }
                    this.isGetAllParams = true;
                    console.log("初始化");
                    this.initSdkFlot();
                }
            },
            initDezhuServe() {
                let userName =
                    this.$store.state.userAccount || this.$store.state.bindPhone,
                webviewUrl =
                    "https://u-qxhy.dezhuyun.com/dzfront/web/home?token=vyqauaeAnyIv&showChat=true&layout=true&hidetitle=true&custom_field_4=" +
                    this.$store.state.userId +
                    "&NAME" +
                    userName +
                    "&TAG=来源平台：游戏服务SDK悬浮球";
                this.$store.commit("getQiyukfUrl", webviewUrl);
            },
            //初始化客服
            initQiYuServe() {
                let that = this;
                // this.$store.commit('getQiyukfUrl', this.$store.state.serviceConfig.h5_url+ this.$store.state.serviceConfig.appkey)
                console.warn("初始化客服组件");
                const s = document.createElement("script");
                s.type = "text/javascript";
                s.src =
                    "https://qiyukf.com/script/" +
                    this.$store.state.serviceConfig.appkey +
                    ".js?hidden=1";
                document.body.appendChild(s);
                this.initQiyuTimer = setInterval(this.isLoadYsf, 1000);
            },
            isLoadYsf() {
                try {
                    if (typeof ysf == "function") {
                        console.log("加载成功");
                        clearInterval(this.initQiyuTimer);
                        this.configKf();
                    }
                } catch (e) {
                    console.error("客服组件加载失败");
                    console.error(e);
                }
            },
            configKf() {
                let that = this;
                let time = this.$common.timeFunc().timeStampMS;
                ysf("config", {
                    uid: that.$store.state.userId,
                    name: that.$store.state.userAccount || that.$store.state.bindPhone,
                    groupid: that.$store.state.serviceConfig.group_id,
                    welcomeTemplateId: that.$store.state.serviceConfig.template_id,
                    data: JSON.stringify([{
                            index: 1,
                            key: "source_platform",
                            label: "来源平台",
                            value: "游戏服务SDK悬浮球",
                        },
                        {
                            index: 2,
                            key: "source_host",
                            label: "来源平台host",
                            value: window.location.host,
                        },
                    ]),
                    success: function(res) {
                        // console.log(ysf('url'))
                        that.$store.commit("getQiyukfUrl", ysf("url"));
                    },
                });
                ysf("onunread", function(result) {
                    // todo
                    that.$store.commit("getQiyuMsgTotal", result.total);
                    // console.log("**************************************************");
                    // console.log(result.total, result.type, result.message);
                });
            },

            //提供给native调用的方法
            iosGetUserTokenCallback(data) {
                let that = this;
                console.log("token:" + data);
                that.saveRequestParame(null, data, null, null);
            },
            iosGetExtInfoCallback(data) {
                let that = this;
                console.log("ExtInfo:" + data);
                that.saveRequestParame(data, null, null, null);
            },
            iosGetMiniIdCallback(data) {
                let that = this;
                console.log("MiniId:" + data);
                that.saveRequestParame(null, null, data, null);
            },
            iosGetPlatformRoleIdCallback(data) {
                let that = this;
                console.log("PlatformRoleId:" + data);
                that.saveRequestParame(null, null, null, data);
            },
            iosGetUserEnterGameDateCallback(data) {
                let that = this;
                console.log("UserEnterGameDate:" + data);
                that.saveRequestParame(null, null, null, null, JSON.parse(data));
            },
            iosGetScreenOrientationCallback(data) {
                let that = this,
                    screenOrientation = null;
                console.log("ScreenOrientation:" + data);
                switch (data) {
                    case "horizontal":
                        screenOrientation = "Horizontal";
                        break;
                    case "vertical":
                        screenOrientation = "Vertical";
                        break;
                    default:
                        screenOrientation = "Vertical";
                        break;
                }
                console.log("screenOrientation:" + screenOrientation);
                that.saveRequestParame(null, null, null, null, null, screenOrientation);
            },
        },
        beforeDestroy() {
            window.removeEventListener("message", this.listenerFunction);
        },
    };
</script>

<style scoped>
    .home {
        width: 100%;
        height: 100vh;
        position: relative;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .mask {
        position: fixed;
        width: 100%;
        height: 100%;
    }
</style>